export const settings = {
  PUBLIC_URL: process.env.PUBLIC_URL ?? "",
  COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN ?? "localhost",
  API_URL: process.env.REACT_APP_API_URL ?? "http://localhost:3005/api",
  AUTH_URL:
    process.env.REACT_APP_AUTH_URL ?? "http://localhost:3002/authenticate?r=",
  AUTH_API_URL:
    process.env.REACT_APP_AUTH_API_URL ?? "http://localhost:3001/api",
};

export const fetchSettings = async () => {
  const response = await fetch("/config.json");
  if (!response.ok) {
    throw new Error("Failed to fetch config");
  }
  Object.assign(settings, await response.json());
};
