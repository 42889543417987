/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * rx2b auth
 * OpenAPI spec version: 0.0.1
 */
import axios from "axios";
import { createAxiosInstance } from "./mutator";

export interface ErrorResponse {
  /** */
  description?: string;
  /** */
  key?: string;
}

export interface User {
  /** */
  email?: string;
  /** */
  externalId?: string;
  /** @nullable */
  githubAvatarUrl?: string | null;
  /** @nullable */
  githubId?: string | null;
  /** @nullable */
  githubLogin?: string | null;
  /** */
  name?: string;
  /** */
  roles?: string[];
}

export interface TokenRefreshRequest {
  /** */
  refreshToken?: string;
}

export interface LoginResponse {
  /** */
  refreshToken?: string;
  /** */
  token?: string;
  user?: User;
}

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const postRefreshToken = (
  tokenRefreshRequest: TokenRefreshRequest,
  options?: SecondParameter<typeof createAxiosInstance>,
) => axios.create(options).post(`/refresh-token`, tokenRefreshRequest);
