import { Spinner } from "@chakra-ui/react";
import React, {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCookies } from "react-cookie";

import { postRefreshToken } from "../api/auth";
import {
  setAuthToken,
  setRefreshToken,
  unsetAuthToken,
  unsetRefreshToken,
} from "../api/token";
import { settings } from "../settings";

export interface User {
  id: number;
  email: string;
  roles: string[];
}

interface AuthenticationDataContext {
  user?: User;
  logout: () => void;
}

const AuthenticationContext = createContext<AuthenticationDataContext>(
  {} as AuthenticationDataContext,
);

type AuthenticationProviderProps = Readonly<{ children: ReactNode }>;

export function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [{ refresh_token: refreshToken }] = useCookies([
    "access_token",
    "refresh_token",
  ]);
  const [hasRefreshed, setHasRefreshed] = useState<boolean>(false);

  useEffect(() => {
    if (loading && !hasRefreshed && refreshToken) {
      setHasRefreshed(true);
      postRefreshToken({ refreshToken }, { baseURL: settings.AUTH_API_URL })
        .then((response) => {
          const { refreshToken, token } = response.data;

          setAuthToken(token);
          setRefreshToken(refreshToken);

          const decodedUser = parseJwt(token!);
          setUser({
            id: decodedUser.id,
            email: decodedUser.email,
            roles: decodedUser.roles,
          });
        })
        .catch((error: unknown) => {
          console.error("Failed to refresh token:", error);
          window.location.href = settings.AUTH_URL + window.location.href;
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (!refreshToken) {
      console.error("No refresh token found");
      window.location.href = settings.AUTH_URL + window.location.href;
      setLoading(false);
    }
  }, [refreshToken, hasRefreshed, loading]);

  const outputData: AuthenticationDataContext = useMemo(
    () => ({
      user,
      logout: () => {
        unsetAuthToken(), unsetRefreshToken();
      },
    }),
    [user, loading],
  );

  return (
    <AuthenticationContext.Provider value={outputData}>
      {loading ? <Spinner /> : children}
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error(
      "useAuthentication() requires a <AuthenticationProvider />",
    );
  }
  return {
    ...context,
  };
}

function parseJwt(token: string): User {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    throw new Error("Could not parse JWT access token");
  }
}
