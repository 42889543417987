import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { settings } from "../settings";
import {
  getAuthToken,
  getRefreshToken,
  setAuthToken,
  setRefreshToken,
} from "./token";
import { postRefreshToken } from "./auth";

// Create the axios instance without baseURL
const authClient = Axios.create();

authClient.interceptors.request.use(
  (config) => {
    // Dynamically set the baseURL from settings just before the request
    config.baseURL = settings.API_URL;

    const accessToken = getAuthToken();
    if (accessToken) {
      config.headers["Authorization"] = `${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor for token refreshing
authClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();

      if (refreshToken) {
        try {
          // Request to refresh token
          const response = await postRefreshToken(
            { refreshToken },
            { baseURL: settings.AUTH_API_URL },
          );

          // Assuming the API response has new authToken and refreshToken
          const newAuthToken = response.data.token;
          const newRefreshToken = response.data.refreshToken;

          // Save tokens
          setAuthToken(newAuthToken);
          if (newRefreshToken) {
            setRefreshToken(newRefreshToken);
          }

          // Retry the original request with the new token
          originalRequest.headers["Authorization"] = `${newAuthToken}`;
          return authClient(originalRequest);
        } catch (refreshError) {
          console.error("Token refresh failed, logging out...");
          return Promise.reject(refreshError);
        }
      } else {
        console.error("No refresh token available, logging out...");
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export const createAxiosInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return authClient({ ...config, ...options }).then((response) => ({
    ...response,
    data: response.data as T,
  }));
};

// In some case with react-query and swr you want to be able to override the return error type
export type ErrorType<Error> = AxiosError<Error>;
