import {
  CheckIcon,
  DeleteIcon,
  SpinnerIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  SectionListItem,
  useDeleteUnsetSection,
  useGetSectionList,
  usePostCreateSectionVersion,
  usePutUpdateSection,
} from "../api/ext-memoire";

export const Editor = () => {
  const [deleteModalData, setDeleteModalData] = useState<SectionListItem>();
  const { data: sectionListData, refetch } = useGetSectionList();
  const { mutateAsync: deleteSection } = useDeleteUnsetSection({
    mutation: {
      onSuccess: () => {
        refetch();
      },
    },
  });
  const { mutateAsync: updateTitle, isPending } = usePutUpdateSection({
    mutation: {
      onSuccess: () => {
        refetch();
      },
    },
  });
  const { mutateAsync: updateContent } = usePostCreateSectionVersion({
    mutation: {
      onSuccess: () => {
        refetch();
      },
    },
  });
  const { sectionId: selectedSectionId } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState<string | undefined>("");
  const [content, setContent] = useState<string | undefined>("");

  const goToSection = useCallback(
    async (sectionId?: string) => {
      navigate(`../${sectionId}`);
    },
    [navigate],
  );

  const onSaveTitle = useCallback(async () => {
    if (selectedSectionId && title) {
      const data = await updateTitle({
        externalId: selectedSectionId,
        data: { title },
      });
      if (data.status !== 200) {
        console.error("error occured");
      } else {
        goToSection(data.data.externalId);
      }
    }
  }, [selectedSectionId, title, updateTitle, goToSection]);

  const onSaveContent = useCallback(async () => {
    if (selectedSectionId && content) {
      const data = await updateContent({
        externalId: selectedSectionId,
        data: { content },
      });
      if (data.status !== 200) {
        console.error("error occured");
      }
    }
  }, [selectedSectionId, updateContent, content]);

  const selectedSection = useMemo(() => {
    const found = sectionListData?.data?.sections?.find(
      (section) => section.externalId === selectedSectionId,
    );
    setTitle(found?.title);
    setContent(found?.latestVersion?.content);
    return found;
  }, [sectionListData, selectedSectionId]);

  useEffect(() => {
    if (!selectedSectionId && sectionListData?.data.sections?.length) {
      goToSection(sectionListData.data.sections[0].externalId);
    }
  }, [selectedSectionId, goToSection, sectionListData]);

  const isContentProper = useMemo(
    () => content === selectedSection?.latestVersion?.content,
    [content, selectedSection],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isContentProper) {
        onSaveContent();
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [isContentProper, onSaveContent]);

  return (
    <Container maxW="full" p={5}>
      <Modal
        isOpen={Boolean(deleteModalData)}
        onClose={() => {
          setDeleteModalData(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sektion wirklich löschen?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Sektion <b>{deleteModalData?.title}</b> wirklich löschen? Die Aktion
            kann nicht mehr rückgängig gemacht werden.
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              onClick={() => {
                setDeleteModalData(undefined);
              }}
            >
              Abbrechen
            </Button>
            {deleteModalData && (
              <Button
                colorScheme="red"
                mr={3}
                onClick={async () => {
                  await deleteSection({
                    externalId: deleteModalData.externalId!,
                  });
                  setDeleteModalData(undefined);
                }}
              >
                Löschen
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Card>
        <CardBody>
          <Grid>
            <GridItem colSpan={3}>
              {selectedSection ? (
                <>
                  <HStack mb={3}>
                    {isPending ? (
                      <Text width="100%" textAlign="right" color="green.500">
                        <SpinnerIcon /> Speichere
                      </Text>
                    ) : (
                      <>
                        {isContentProper ? (
                          <Text
                            width="100%"
                            textAlign="right"
                            color="green.500"
                          >
                            <CheckIcon /> Gespeichert
                          </Text>
                        ) : (
                          <Text
                            width="100%"
                            textAlign="right"
                            color="yellow.500"
                          >
                            <WarningTwoIcon /> Ungespeicherte Änderungen
                          </Text>
                        )}
                      </>
                    )}
                    <IconButton
                      variant="outline"
                      aria-label="Search database"
                      icon={<DeleteIcon />}
                      onClick={() => {
                        setDeleteModalData(selectedSection);
                      }}
                    />
                  </HStack>
                  <Box mb={2}>
                    <Input
                      placeholder="large size"
                      size="lg"
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                      onBlur={onSaveTitle}
                    />
                  </Box>
                  <Box>
                    <ReactQuill
                      theme="snow"
                      value={content}
                      onChange={setContent}
                    />
                  </Box>
                </>
              ) : (
                <>Bitte eine Sektion auswählen</>
              )}
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
    </Container>
  );
};
