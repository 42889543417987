import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Notifications from "./components/Notifications";
import { AuthenticationProvider } from "./providers/AuthenticationProvider";
import { NotificationsProvider } from "./providers/NotificationProvider";
import { Editor } from "./screens/Editor";
import { Navbar } from "./components/Navbar";

export default function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <NotificationsProvider autoDismiss={2000}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <AuthenticationProvider>
              <Notifications />
              <Navbar />
              <Routes>
                <Route key="sections" path={`/`} element={<Editor />} />
                <Route
                  key="section"
                  path={`/:sectionId`}
                  element={<Editor />}
                />
              </Routes>
            </AuthenticationProvider>
          </BrowserRouter>
        </NotificationsProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
}
